<template>
   
      
    <TitleView 
    title="Path Not Found"/>
 
 
   </template>
   
   <script>

 import TitleView from "../components/Title/TitleView.vue";
   
   export default {
     name: "NotFoundComponent",
     components: {
       TitleView
     }
     
   };
   </script>
   